import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
} from '@material-ui/core';
import DescriptionIcon from '@mui/icons-material/Description';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { Maybe } from '../generated/graphql';
import { normalizeRouteIdForRoute } from '../lib/normalizeRouteId';
import { getUserName } from '../lib/useRoles';
import { AutoReset } from '../utils/AutoReset';
import { AggregatesLoadingList } from './AggregatesLoadingList';
import { CenteredBox } from './CenteredBox';
import { AccordionContainer } from './form/AccordionContainer';
import { CommonTextField } from './form/CommonTextField';
import { CostAllocationItemInterface } from './form/CostAllocationFields';
import { FilesField } from './form/FilesField';
import { LegFieldInput } from './form/LegFields';
import { SubcontractorFields } from './form/SubcontractorFields';
import { SupressEnterForm } from './form/SupressEnterForm';
import { LegsFields } from './LegsFields';

export type SporadicRouteFormInput = {
  isCreatedFromPlanned: boolean;
  isCreatedFromTerminal: boolean;
  transportationDate: string;
  agreedPrice?: Maybe<number>;
  currency: string;
  note: string;
  externalNote: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  capacity?: Maybe<number>;
  kilometer?: Maybe<number>;
  weight?: Maybe<number>;
  routeId: string;
  invoiceNumber: Maybe<string> | undefined;
  invoiceNote: Maybe<string> | undefined;
  subcontractorId?: number;
  dispatcherId?: number;
  costAllocation?: Maybe<{
    id?: number;
    items: CostAllocationItemInterface[];
  }>;
  files: {
    id: number;
    originalname: string;
    path: string;
    createdBy: string;
    createdAt: Date;
  }[];
  ftl:
    | {
        cdc: string;
        cmr: string;
        price: number;
        currency: string;
        customerId?: Maybe<number>;
        customerNumber: string;
        customerReference: string;
        invoiceNote: string;
      }
    | undefined;
  legs: LegFieldInput[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(1, 'auto'),
  },
  subResources: {
    margin: theme.spacing(1, 'auto'),
    width: '95%',
  },
  button: {
    margin: theme.spacing(1, 'auto'),
  },
  formContent: {
    margin: theme.spacing(1, 0, 2, 1),
    padding: theme.spacing(0, 2, 2, 2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      padding: theme.spacing(1, 0),
    },

    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface RouteFormProps<T> {
  initialValues: T;
  onSubmit: (values: T) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  terminal?: boolean;
  hideCostAllocation?: boolean;
  validationSchema: ObjectSchema<ObjectShape>;
  fromCreateRoute?: string;
}

export function TerminalRouteForm<T extends SporadicRouteFormInput>(
  props: RouteFormProps<T>,
) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    readOnly = false,
    terminal = false,
    validationSchema,
    fromCreateRoute,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const user = getUserName();
  const collapseSections = useCallback(() => {
    if (localStorage.getItem('collapseTruckSections_' + user) === 'true') {
      return true;
    } else {
      return false;
    }
  }, [localStorage.getItem('collapseTruckSections_' + user)]);
  const [aggreementOpen, setAggreementOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [noteOpen, setNoteOpen] = useState<boolean>(!collapseSections());
  const [subcontractorOpen, setSubcontractorOpen] = useState<boolean>(
    !collapseSections(),
  );

  const trafficReadOnly = terminal || readOnly;
  //const [costAllocationOpen, setCostAllocationOpen] = useState<boolean>(false);
  const [filesOpen, setFilesOpen] = useState<boolean>(true);
  return (
    <Paper className={classes.formContent}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnBlur
        validateOnChange={false}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema, true, value);
          } catch (err) {
            const errors = yupToFormErrors<{ costAllocation: string }>(err); //for rendering validation errors
            return errors;
          }

          return {};
        }}
        onSubmit={normalizeRouteIdForRoute(onSubmit)}
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Grid container>
              <AutoReset readOnly={trafficReadOnly} />
              <Grid item xs={6}>
                <AccordionContainer
                  open={aggreementOpen}
                  errors={[props.errors.transportationDate]}
                  setOpen={setAggreementOpen}
                  accordionTitle={t('attributeGrouping.agreement')}
                >
                  <Box width="100%" display="flex" flexDirection="column">
                    <CenteredBox>
                      <CommonTextField
                        name="routeId"
                        className={classes.field}
                        readOnly={trafficReadOnly}
                      />
                    </CenteredBox>
                    <CenteredBox>
                      <CommonTextField
                        name="transportationDate"
                        type="date"
                        className={classes.field}
                        readOnly={trafficReadOnly}
                      />
                    </CenteredBox>

                    <CenteredBox>
                      <CommonTextField
                        name="capacity"
                        className={classes.field}
                        type="number"
                        readOnly={trafficReadOnly}
                      />
                      <CommonTextField
                        name="kilometer"
                        className={classes.field}
                        type="number"
                        readOnly={trafficReadOnly}
                      />
                      <CommonTextField
                        name="weight"
                        labelText={'weightKg'}
                        className={classes.field}
                        type="number"
                        readOnly={trafficReadOnly}
                      />
                    </CenteredBox>
                  </Box>
                </AccordionContainer>

                <AccordionContainer
                  open={noteOpen}
                  setOpen={setNoteOpen}
                  accordionTitle={t('attributeGrouping.note')}
                >
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="note"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={readOnly}
                    />
                  </CenteredBox>
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="externalNote"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={readOnly}
                    />
                  </CenteredBox>
                </AccordionContainer>
              </Grid>
              <Grid item xs={6}>
                <AccordionContainer
                  open={subcontractorOpen}
                  errors={[props.errors.subcontractorId]}
                  setOpen={setSubcontractorOpen}
                  accordionTitle={t('resource.subcontractor.capitalized')}
                >
                  <SubcontractorFields readOnly={readOnly} />
                </AccordionContainer>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.buttonWrapper}>
                  {!(readOnly && props.values.files.length === 0) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.documentFile.plural'),
                      })}`}
                    >
                      <IconButton
                        color={filesOpen ? 'primary' : undefined}
                        onClick={() => {
                          setFilesOpen(!filesOpen);
                        }}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FilesField
                  files={props.values.files}
                  show={filesOpen}
                  readOnly={readOnly}
                  setOpen={setFilesOpen}
                  onHideClicked={() => setFilesOpen(false)}
                  onUpdate={(files) => {
                    props.setFieldValue('files', files);
                  }}
                />
              </Grid>
              <AggregatesLoadingList
                loadingListItems={props.values.legs.flatMap(
                  (leg) => leg.loadingListItems,
                )}
                capacity={props.values.capacity}
              />

              <Grid item xs={12}>
                <Divider />
                <Box mt="5px">
                  <LegsFields
                    trafficReadOnly={trafficReadOnly}
                    readOnly={readOnly}
                    fieldsPath="legs"
                    fromCreateRoute={fromCreateRoute}
                    isCreateTerminalRoute={true}
                    isFromTerminal={true}
                    isTruckLoading={
                      initialValues.legs[0].locationId ? true : false
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            {!readOnly && (
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            )}
          </SupressEnterForm>
        )}
      </Formik>
    </Paper>
  );
}
